import * as XLSX from "xlsx";
import axios from 'axios';

export const searchVendedor = (response, params ) => {

  if (params) {
      let filter = response.data.filter((e) => e.nombre.toLowerCase().includes(params.toLowerCase()));
      response.data = filter;
      response.data.length = filter.length;
    }
    
  return  { data: response.data  ,
            pageSize: 50,
            start: 0,
            total: response.data.length}
}

export const generateRandomCode = (() => {
    const UPPER_CHARACTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    const LOWER_CHARACTERS = "abcdefghijklmnopqrstuvwxyz".split("");
    const NUMERIC_CHARACTERS = "1234567890".split("");
    const NO_CHARACTERS = "$%&".split("");

    return (length, exclude) => {

      return  (UPPER_CHARACTERS[Math.floor(Math.random() * UPPER_CHARACTERS.length)]
        + new Array(length - 4).fill(null).map(() => {
          return LOWER_CHARACTERS[Math.floor(Math.random() * LOWER_CHARACTERS.length)];
        }).join("")
        + new Array(exclude ? 3 : 2).fill(null).map(() => {
          return NUMERIC_CHARACTERS[Math.floor(Math.random() * NUMERIC_CHARACTERS.length)];
        }).join(""))
        + (exclude ? "" : NO_CHARACTERS[Math.floor(Math.random() * NO_CHARACTERS.length)]);
    }
  })();

  export const formatoPeru = (locale, currency, number) => {  //formato numerico perú
    // const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    // const rep = '$1,';
    // return number.toString().replace(exp,rep);
    if (number === 0){
        return number
    }else{
  
      return new Intl.NumberFormat(locale, { 
        style: 'currency', 
        currency, 
        currencyDisplay: "code" 
      })
      .format(number)
      .replace(currency, "")
      .trim();
    }
  }

export const arregloExport =(head,body) =>{
  var carArray = [];
  var aux = []
   
  for (var c in head){
    aux[c] = Object.values(head[c])[1];
  }
  
  carArray.push(aux);

  for (var d in body){
    let x = Object.values(body[d]);
    carArray.push(x);
    
  }

  return (carArray)
}

export const exportHead =(head) =>{
  var carArray = [];
  var aux = []
   
  for (var c in head){
    aux[c] = Object.values(head[c])[1];
  }
  
  carArray.push(aux);

  return (carArray)
}


//funcion que cortar arreglo  dependiendo las columnas 
export const cortarArreglo=(head,body) =>{
//console.log(head, body)
var temp = [];
var arreglito = [];
var long = 0
var repetidos = [];

//const totalProps = body.reduce((a, obj) => a + Object.keys(obj).length, 0);

for (var c in head){
  temp[c] = Object.values(head[c])[1];
}

long = Object.keys(body[0]).length

arreglito.push(temp);

for (var bod = 0; bod < body.length; bod++) {
  
  for (var i = 0; i<long; i++)
  {
    
    for (var e in head)
    {

        if(Object.keys(body[bod])[i] === Object.values(head[e])[0]) 
        {
            let x = Object.values(body[bod])[i];

            repetidos.push(x)

            if(repetidos.length === head.length)
            {
              arreglito.push(repetidos)
              repetidos = []
            }
        }
    }
  }
}
    return arreglito ;
}


export const exportarCSVFecha = (jsonData, fileName, celda) => {
    /*PARAMETROS:
    jsonData = Arreglo que tiene la data
    fileName = Nombre que tendra el archivo
    celda = Letra de la celda de excel donde debe convertir */
    const ws = XLSX.utils.aoa_to_sheet(jsonData, { cellDates: true, dateNF: 'dd/mm/yyyy' });
     for(var i = 0; i <jsonData.length; ++i) {
      // ws[`A${i+2}`].z = 'd/m/yy';
      if (i+1 > 1 ){
            var faux ;
            ws[`${celda}${i+1}`].t = 'd';
            ws[`${celda}${i+1}`].z = 'DD/MM/YYYY';
            faux=new Date(conversionCsvAString( ws[`${celda}${i+1}`].v));
            //console.log(faux)
            faux.setMinutes(faux.getMinutes()+1)
            ws[`${celda}${i+1}`].v =  faux;
           // ws[`A${i+1}`].w = '12/08/2018 23:59:16';
      }
    }

    const wb = { Sheets: { data: ws }, SheetNames: ['data'], SSF : ['d-mmm-yy'] };
    ///XLSX.utils.book_append_sheet(wb, ws, 'Somename');// adiciona otra hoja
       XLSX.writeFile( wb, fileName+'.XLSX' );
    
}

function conversionCsvAString(responseDate) {
  
  let dateComponents = responseDate.split(" ");

  var s = []
  let datePieces = dateComponents[0].split("/");
  //console.log(datePieces)
  //let timePieces = dateComponents[1].split(":");
  for(var c in datePieces)
  {
       s[c] = datePieces[2];
       s[1] = datePieces[0];
       s[2] = datePieces[1];

  }
  //console.log(responseDate);
  //console.log(s);

  return(s)
}



export const exportExcel = (jsonData, fileName) => {
  
    const ws = XLSX.utils.aoa_to_sheet(jsonData, { cellDates: true, dateNF: 'dd/mm/yyyy' });
       
     for(var i = 0; i <jsonData.length; ++i) {
      // ws[`A${i+2}`].z = 'd/m/yy';
      if (i+1 > 1 ){
            var faux ;
            ws[`A${i+1}`].t = 'd';
            ws[`A${i+1}`].z = 'DD/MM/YYYY';
            faux=new Date(conversionMeridianoAString( ws[`A${i+1}`].v));
            faux.setMinutes(faux.getMinutes()+1)
            ws[`A${i+1}`].v =  faux;
           // ws[`A${i+1}`].w = '12/08/2018 23:59:16';
      }
    }

    const wb = { Sheets: { data: ws }, SheetNames: ['data'], SSF : ['d-mmm-yy'] };
    ///XLSX.utils.book_append_sheet(wb, ws, 'Somename');// adiciona otra hoja
       XLSX.writeFile( wb, fileName+'.XLSX' );
    
}


function conversionMeridianoAString(responseDate) {
    let dateComponents = responseDate.split('T');
  

    let datePieces = dateComponents[0].split("-");

   // console.log(datePieces)
    return(datePieces)
}

export const exportChannelDatabaseExcel = (jsonData, fileName) => {

 for(var i = 0; i <jsonData.length; ++i) {
      if (i+1 > 1 ){ 
        if ( jsonData[i][14] !== undefined){
           jsonData[i][14] = parseFloat(jsonData[i][14])
        //jsonData[i] = parseFloat(element[14])
        }
        
      }
  }

const ws = XLSX.utils.aoa_to_sheet(jsonData, { cellDates: true, dateNF: 'dd/mm/yyyy' });
  const wb = { Sheets: { data: ws }, SheetNames: ['data'], SSF : ['d-mmm-yy'] };
  ///XLSX.utils.book_append_sheet(wb, ws, 'Somename');// adiciona otra hoja
     XLSX.writeFile( wb, fileName+'.XLSX' );
  
}

export const exportToSpreadsheet = (data, fileName) => {
    const fileExtension = ".xlsx";
    //Create a new Work Sheet using the data stored in an Array of Arrays.
    
    const workSheet = XLSX.utils.aoa_to_sheet(data);
   
   //---- XLSX.utils.sheet_add_json(workSheet, json, { origin: -1, display: true }, { cellDates: true, dateNF: 'YYYYMMDD HH:mm:ss' });

    // Generate a Work Book containing the above sheet.
    const workBook = {
      Sheets: { data: workSheet, cols: [] },
      SheetNames: ["data"],
    };
    // Exporting the file with the desired name and extension.

    const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
    let csvHref = window.URL.createObjectURL(new Blob(([excelBuffer])));

    let link = document.createElement('a');
    link.href = csvHref;
    link.setAttribute('download', fileName + fileExtension);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export  const sort_by = (field, reverse,  tipo) => {

  if (field === undefined  ||  tipo === undefined){
      return
  }
  let  primer = null;
  if (typeof(tipo) === 'number'){
    primer = parseInt;
  } else if (typeof(tipo) === 'boolean' ){
      primer = null;
  }else if (typeof(tipo) === 'string'){

      primer = (a) => a === undefined ? null : a.toUpperCase();
  }else if (typeof(tipo) === 'object'){
      primer = null;

  }else if (typeof(tipo) === 'object'  &&  field ===  'string'){
     // primer = null;
     primer = (a) =>  a.toUpperCase();
  }
  else {
      primer = null; 
  }

  const key = primer ?
    function(x) {
      return primer(x[field])
    } :
    function(x) {
      return x[field]
    };

  reverse = !reverse ? 1 : -1;

  return function(a, b) {
    // return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    // eslint-disable-next-line no-sequences
    return ((a = key(a), b = key(b)), reverse * ((a > b) - (b > a)));
  }
}
export const handleDownLoadFile = (item) => {
  //console.log(item)
  let link = document.createElement('a');

  link.href = item;
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const exportExcelFecha = (jsonData, fileName, celda) => {
  /*PARAMETROS:
   jsonData = Arreglo que tiene la data
   fileName = Nombre que tendra el archivo
   celda = Letra de la celda de excel donde debe convertir */	
   //console.log(jsonData)
   const ws = XLSX.utils.aoa_to_sheet(jsonData, { cellDates: true, dateNF: 'dd/mm/yyyy' });
      
    for(var i = 0; i <jsonData.length; ++i) {
     // ws[`A${i+2}`].z = 'd/m/yy';
     if (i+1 > 1 ){
           var faux ;
           ws[`${celda}${i+1}`].t = 'd';
           ws[`${celda}${i+1}`].z = 'DD/MM/YYYY';
           faux=ws[`${celda}${i+1}`].v;
           //console.log(faux)
           faux=new Date(conversionMeridianoAString( ws[`${celda}${i+1}`].v));
           faux.setMinutes(faux.getMinutes()+1)
           ws[`${celda}${i+1}`].v =  faux;
          // ws[`${celda}${i+1}`].w = '12/08/2018 23:59:16';
          //console.log("PASA: ",faux)
     }
   }

   const wb = { Sheets: { data: ws }, SheetNames: ['data'], SSF : ['d-mmm-yy'] };
   ///XLSX.utils.book_append_sheet(wb, ws, 'Somename');// adiciona otra hoja
      XLSX.writeFile( wb, fileName+'.XLSX' );
   
}



/*   maps icons,    -----------------------------------------------------------------*/
export const no = 'http://maps.google.com/mapfiles/kml/pal2/icon13.png'
export const mye= './images/yellow-dot-E.png'
    
export const mapicon =[
           'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
            'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png' ,
            'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' ,
            'http://maps.google.com/mapfiles/ms/icons/gray-dot.png' ,
            'http://maps.google.com/mapfiles/ms/icons/green-dot.png' ,
            'http://maps.google.com/mapfiles/ms/icons/pink-dot.png' ,
            'http://maps.google.com/mapfiles/ms/icons/orange-dot.png' ,
            'http://maps.google.com/mapfiles/ms/icons/purple-dot.png' ,
            'http://maps.google.com/mapfiles/ms/icons/white-dot.png' ,
            'http://maps.google.com/mapfiles/kml/paddle/orange-stars.png' ,
            'http://maps.google.com/mapfiles/kml/paddle/wht-square.png' ,
            'http://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_black.png',
            'http://maps.google.com/mapfiles/kml/paddle/ylw-stars-lv.png',
            'http://maps.google.com/mapfiles/kml/paddle/purple-stars-lv.png',
            'http://maps.google.com/mapfiles/kml/paddle/grn-stars-lv.png',
            'http://maps.google.com/mapfiles/kml/paddle/blu-circle-lv.png'
    ]

//genera iconos segun color que recibe
export function customIcon (opts) {
      return Object.assign({
        //path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
        fillColor: '#34495e',
        fillOpacity: 1,
         strokeColor: '#000',
        // strokeWeight: 2,
        //scale: 1,
      }, opts);
}

export const conCat = (data) => {
      const temp= []
      //return _(data)
      // eslint-disable-next-line array-callback-return
      data.map((item, i) =>{
           temp.push({nombre: item.Nombre, url: mapicon[i]})
          // temp.push({nombre: item.Nombre})
         }
      )

    return temp;
}


export const cualUrl = (item, miarray) => {
    const temp = conCat(miarray).find(element => element.nombre === item)
    return temp;
}


//Funcion que permite asignar por default un distribuidor en los filtros en caso
//el usuario tenga asiganando uno solo.
export const getDistribuidorDefault = async (onUpdateFieldFilter, dispatch) => {
    
    let url = `https://accounts.salesmobilitytools.com/api/v1/distribuidores/porsesion?pageSize=50&start=0`
    let response = await axios.get(encodeURI(url));

    //console.log("DD:", response.data.data)
    var dist = response.data.data
    if(dist.length === 1)
      {
                dispatch(onUpdateFieldFilter('distribuidorId', dist[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${dist[0].razonSocial}`));
      }

  }

  export const Arol  = [
    { nombre: 'Agente' },
    { nombre: 'Director general' },
    { nombre: 'Jefe zona' },
    { nombre: 'Supervisor' },
    { nombre: 'Vendedor' },
    { nombre: 'Planeación Colombia' },
    { nombre: 'Precios Colombia' },
    { nombre: 'Asistente de Ventas' },
    { nombre: 'Agente Peru' },
    { nombre: 'Marketing Peru' },
    { nombre: 'EC Agente' },
    { nombre: 'EC Director general' },
    { nombre: 'EC Jefe zona' },
    { nombre: 'EC Supervisor' },
    { nombre: 'EC Consultor Ventas' },
    { nombre: 'EC Vendedor' },
  ]
/* -----------------------------------------------------------------------------------------------*/
// export const aOlas  = [
//   { 'olaId': 1, 'dOla': '2020', 'desde': '01/01/2020', 'hasta': '31/12/2021' },
//   { 'olaId': 2, 'dOla': '1Q 2021', 'desde': '01/01/2021', 'hasta': '31/12/2021' },
//   { 'olaId': 4, 'dOla': '1Q 2022', 'desde': '01/01/2022', 'hasta': '31/03/2022' },
//   { 'olaId': 5, 'dOla': '2Q 2022', 'desde': '01/04/2022', 'hasta': '30/06/2022' },
//   { 'olaId': 6, 'dOla': '3Q 2022', 'desde': '01/07/2022', 'hasta': '30/09/2022' },
//   { 'olaId': 7, 'dOla': '4Q 2022', 'desde': '01/10/2022', 'hasta': '31/12/2022' },
// ]

export const JefeZona  = [
  { 'olaId': 1, 'NombreJefeZona': 'Byron Cajas', 'NombreZona': 'Austro Sur' },
  { 'olaId': 2, 'NombreJefeZona': 'Mauricio Castro', 'NombreZona': 'Costa'},
  { 'olaId': 4, 'NombreJefeZona': 'Ruben Chavez', 'NombreZona': 'Sierra Centro & Oriente'},
  { 'olaId': 5, 'NombreJefeZona': 'Siboney Ceballos', 'NombreZona': 'Sierra Norte' }
]

export const amarca = [
  { 'marcaId': 1, 'dMarca': 'MOBIL' },
  { 'marcaId': 2, 'dMarca': 'TERPEL' }, 
  { 'marcaId': 0, 'dMarca': 'TODOS' }
]

export const listaD = [
  { 'listaId': 5, 'dLista': 'Tutorial' },
  { 'listaId': 6, 'dLista': 'Catalgo' }, 
]

export const tipoFormato = [
  { 'tipoId': 1, 'dTipo': 'Video' },
  { 'tipoId': 2, 'dTipo': 'Pdf' }, 
  { 'tipoId': 3, 'dTipo': 'Imagen' },
]


export const alob = [
  { 'lobId': 1, 'dLob': 'CVL' },
  { 'lobId': 2, 'dLob': 'MCO' },
  { 'lobId': 3, 'dLob': 'PVL' },
  { 'lobId': 4, 'dLob': 'IND' },
  { 'lobId': 0, 'dLob': 'TODOS' }
]

export const alob2 = [
  { 'lobId': 1, 'dLob': 'CVL' },
  { 'lobId': 2, 'dLob': 'MCO' },
  { 'lobId': 3, 'dLob': 'PVL' },
  { 'lobId': 4, 'dLob': 'IND' },
  //{ 'lobId': 0, 'dLob': 'TODOS' }
]


export const tipos = [
  {'tipoId': 1, 'dTipo':'Fachada'},
  {'tipoId': 2, 'dTipo':'Pared'},
  //{'tipoId': 3, 'dTipo':'Góndolas/Exhibidores'}

]

//no se usa actualmente
export const lugar = [
  {'lugarId': 1, 'dLugar': 'Departamento'},
  {'lugarId': 2, 'dLugar': 'Provincia'},
  {'lugarId': 3, 'dLugar': 'Distrito'}

]


//no se usa actualmente
export const marcas = [
  {'marcasId':1,'dMarcas': 'Amalie'},
  {'marcasId':2,'dMarcas': 'Bardahl'},
  {'marcasId':3,'dMarcas': 'Cam2'},
  {'marcasId':4,'dMarcas': 'Castrol'},
  {'marcasId':5,'dMarcas': 'Chevron'},
  {'marcasId':6,'dMarcas': 'Gulf'},
  {'marcasId':7,'dMarcas': 'Havoline'},
  {'marcasId':8,'dMarcas': 'Honda'},
  {'marcasId':9,'dMarcas': 'Husqvarna'},
  {'marcasId':10,'dMarcas': 'Hyundai Xteer'},
  {'marcasId':11,'dMarcas': 'Ipone'},
  {'marcasId':12,'dMarcas': 'Kendall'},
  {'marcasId':13,'dMarcas': 'Kixx'},
  {'marcasId':14,'dMarcas': 'Liqui Moly'},
  {'marcasId':15,'dMarcas': 'Lubritek'},
  {'marcasId':16,'dMarcas': 'Mobil'},
  {'marcasId':17,'dMarcas': 'Motul'},
  {'marcasId':18,'dMarcas': 'Peak'},
  {'marcasId':19,'dMarcas': 'Petroamérica'},
  {'marcasId':20,'dMarcas': 'Petronas'},
  {'marcasId':21,'dMarcas': 'Shell'},
  {'marcasId':22,'dMarcas': 'Repsol'},
  {'marcasId':23,'dMarcas': 'Valvoline'},
  {'marcasId':24,'dMarcas': 'Vextrom'},
  {'marcasId':25,'dMarcas': 'Vistony'},
  {'marcasId':26,'dMarcas': 'Otra marca'},
  {'marcasId':27,'dMarcas': 'Sin brandear'},
  
]

export const tiposIdentificacion = [
  {'identificacionId':1,'dIdentificacion': 'Afiche Nuevo Producto'},
  {'identificacionId':2,'dIdentificacion': 'Afiche Informativo'},
  {'identificacionId':3,'dIdentificacion': 'Afiche Promocional'},
  {'identificacionId':4,'dIdentificacion': 'Afiche Formativo/Educativo'},
  {'identificacionId':5,'dIdentificacion': 'Activacion'},
  {'identificacionId':6,'dIdentificacion': 'Promotoria'},
]

export const aCategoria = [
  {'segmentoId': 1, 'nombre': 'ALMACEN'},
  {'segmentoId': 2, 'nombre': 'CENTROS DE LUBRICACION'},
  {'segmentoId': 3, 'nombre': 'CONCESIONARIOS'},
  {'segmentoId': 4, 'nombre': 'ESTACIONES DE SERVICIO'},
  {'segmentoId': 5, 'nombre': 'MAYORISTA'},
  {'segmentoId': 6, 'nombre': 'TALLER'}
]

export const aGondola =[
  {'gondola':1, 'dGondola':'SI'},
  {'gondola':0, 'dGondola':'NO'},
]

export const aTipoEstablecimiento = [
  {title: 'LUBRICENTRO', establecimiento: 'LUBRICENTRO' },
  {title: 'TALLERES', establecimiento: 'Talleres'}
]

export const aProducto = [
  {dProducto: "Mobil Delvac 1 ESP", productoId: 1 },
  {dProducto: "Neptuna 2 T Racing", productoId: 2},
  {dProducto: "Shell Rimula R4 X", productoId: 3}
]

