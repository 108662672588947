import axios from "axios";
import format from "date-fns/format";
import { IdentityUrl } from "../../../config";
import {
  closeAdvancedSearch,
  closeMessageBox,
  openAdvancedSearch,
  openMessageBox,
} from "../../main/actions";
import {
  ERROR_OLAS_LIST,
  RECEIVE_OLAS_LIST,
  RESTART_STATE,
  SET_MASK,
  SET_ORDER,
  SET_SELECTED_ROW,
  UPDATE_FIELD_FILTER,
  UPDATE_FIELD_FILTERH,
  UPDATE_FIELD_ENTITYS,
  RECEIVE_OLAS_DET,
  RECEIVE_HISTORICO_DETAIL,
  RECEIVE_OLAS_PORCOBRAR,
  ON_SET_BANDEJA,
  RECEIVE_PASE_PEDIDO_LIST,
  RECEIVE_APROBAR_COBRANZA_LIST,
  RECEIVE_COBRANZA_DET,
  HANDLE_SHOW
} from "./types";

import { exportToSpreadsheet, cortarArreglo, searchVendedor} from '../../../helpers/commons'

export const detallePedido = (item) => async (dispatch, getState) => {
  //console.log(item)

  if (getState().bandejaaprobacion.store.loading) {
    return;
  }

  dispatch({ type: SET_MASK, loading: true });

  try {
    let url =
      `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Pedidos/` +
      `${item["PedidoId"]}`;

    let response = await axios.get(encodeURI(url));
    let datad = response;
    //console.log(datad)
    dispatch({ type: RECEIVE_OLAS_DET, payload: datad.data.detalle });

    let url2 =
    `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Clientes/promediogalones?clienteId=18728`;
    let responsePromedio = await axios.get(encodeURI(url2));
    let dataGalones = responsePromedio;
    //console.log(dataGalones)
    
    dispatch({ type: UPDATE_FIELD_FILTER, key: 'promedio', value :dataGalones.data });


    let url3 = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Clientes/promediopesos?`+
    `distribuidorId=${getState().bandejaaprobacion.filters.distribuidorId}` +
    `&clienteId=18728`
    let responsePesos = await axios.get(encodeURI(url3));
    let dataPesos = responsePesos;
    //console.log(dataPesos)
    dispatch({ type: UPDATE_FIELD_FILTER, key: 'pesos', value :dataPesos.data });


  } catch (error) {
    let msgError = "";
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_OLAS_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0
            ? "No existe Registro  para esta fecha "
            : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      msgError = "Error al conectar con el servidor....";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const btnApprove = () =>(dispatch) =>{

  dispatch(
    openMessageBox({
      button: "yesno",
      icon: "question",
      message: "¿Está seguro de aprobar el registro?",
      callback: (btn) => dispatch(onApprove(btn)),
    })
  );
};

export const onApprove = (btn) => async (dispatch, getState)  =>{

  dispatch(closeMessageBox());
  //console.log(btn, 'Alyy')

  if((btn === "yes" && getState().bandejaaprobacion.store.detalleaprobarcobranza.length !== 0) || 
  (btn === "yes" && getState().bandejaaprobacion.store.detalle.length !== 0) ){
    dispatch(HandleShows(false))

    var op = getState().bandejaaprobacion.panel
    var a = ''
    var b =''
    switch(op){
      case 0 :
        a = getState().bandejaaprobacion.store.detalle[0].pedidoId
        b ='PED'
      break;
      case 1 :
        a = getState().bandejaaprobacion.store.detalle[0].pedidoId
        b ='DP'
      break;
      case 2 :
       a = getState().bandejaaprobacion.store.detalleaprobarcobranza[0].CobranzaId
       b ='COB'
        break;
      default:
      //  console.log('HOLIS')
    } 
   
    dispatch(actualiza(a,'A','',b))
    

  }else{
   // console.log('Aqui cuando cierra')
    dispatch(closeMessageBox())
    return
  }
};

export const actualiza = (a, b, c,d) => async (dispatch, getState) => {
  //console.log('Pasando descuento')

  //console.log(a,b,c,d)

  dispatch({ type: SET_MASK, loading: true });
  try {
    
    let head = {
      pkOrigen: a,
      estado: b,
      observacion: c,
      tipo: d
    };

    let url = `${
      getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Agentes/estadoAutorizacion`;
    await axios.put(url, head, {
      headers: { "Content-Type": "application/json" },
    });

    // eslint-disable-next-line no-unused-vars
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "info",
        message: "Registro Guardado con Exito..",
        callback: () => dispatch(closeMessageBox()),
      })
    );

  } catch (error) {
    let msgError = "";
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_OLAS_LIST });

    if (error.response) {
      if (error.response.status === 400 || error.response.status === 500) {
        msgError =
          error.response.data.eventLogId === 0  ? error.response.data.message   : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0  ? ""  : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
   
  }
};

export const onExport = () => async (dispatch, getState) => {
  if (getState().bandejaaprobacion.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  
  try {
    dispatch({ type: SET_MASK, loading: true });

    let json = []
    let head = []
    let op = getState().bandejaaprobacion.panel

    //Para definir cual es el grid a exportar 
    switch (op) {
      case 0:
        console.log('Aly cero')
        if (getState().bandejaaprobacion.store.dataPase.length === 0) {
          dispatch(
            openMessageBox({
              button: "ok",
              icon: "warning",
              message: "No hay información para exportar.",
              callback: () => dispatch(closeMessageBox()),
            })
          );
          return;
        }
        head = getState().bandejaaprobacion.tablePase.columnsexport;
       json = getState().bandejaaprobacion.store.dataPase;
      break;
      case 1:
        if (getState().bandejaaprobacion.store.data.length === 1) {
          dispatch(
            openMessageBox({
              button: "ok",
              icon: "warning",
              message: "No hay información para exportar.",
              callback: () => dispatch(closeMessageBox()),
            })
          );
          return;
        }
        console.log('Aly uno')
        head = getState().bandejaaprobacion.table.columnsexport;
        json = getState().bandejaaprobacion.store.data;
      break;
      case 2:
        if (getState().bandejaaprobacion.store.dataAprobarCobranza.length === 2) {
          dispatch(
            openMessageBox({
              button: "ok",
              icon: "warning",
              message: "No hay información para exportar.",
              callback: () => dispatch(closeMessageBox()),
            })
          );
          return;
        }
        console.log('Aly dos')
        head = getState().bandejaaprobacion.tableAprobarCobranza.columnsexport;
        json = getState().bandejaaprobacion.store.dataAprobarCobranza;
      break;
      default:
      break;
    }

  //   if (getState().bandejaaprobacion.panel === 0) {

  //     head = getState().bandejaaprobacion.table.columnsexport;
  //     json = getState().bandejaaprobacion.store.data;
  // }else{
  //     head = getState().bandejaaprobacion.tablePase.columnsexport;
  //     json = getState().bandejaaprobacion.store.dataPase;
  // }
    // eslint-disable-next-line no-array-constructor

    var temp = new Array();
    for (var c in head) {
      let x = Object.values(head[c])[0];
      temp.push(x);
    }
    // eslint-disable-next-line no-array-constructor
    var carArray = new Array();
    carArray.push(temp);

    for (var i in json) {
      let x = Object.values(json[i]);
      carArray.push(x);
    }

    exportToSpreadsheet(carArray, "BandejaAprobación");

  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0   ? ""  : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
  }
};

export const btnSearch = () => async (dispatch, getState) => {  
  if (getState().bandejaaprobacion.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  if (getState().bandejaaprobacion.store.loading) {
    return;
  }

  dispatch({ type: SET_MASK, loading: true });

  try {
    let url =
      `${
        getState().main.resources.uriSvcSalesMobilityAPI}api/v1/reportes/reporteaprobacionpedidos?` +
      `distribuidorId=${getState().bandejaaprobacion.filters.distribuidorId}` +
      `&desde=${format(getState().bandejaaprobacion.filters.desde, "yyyy-MM-dd")}` +
      `&hasta=${format(getState().bandejaaprobacion.filters.hasta, "yyyy-MM-dd")}` +
      `&vendedorid=${getState().bandejaaprobacion.filters.vendedorId}`
      // +
      // `&estado=${getState().bandejaaprobacion.filters.estado}`;

    let response = await axios.get(encodeURI(url));
    let data = response.data;

    console.log(data , 'Para verificar estados ')
    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    const head = [
      { dataKey: "PedidoId", label: "PedidoId", width: 100 },
      { dataKey: "Fecha", label: "Fecha", width: 100 },
      { dataKey: "Estado", label: "Estado", width: 100 },
      { dataKey: "NIT/RUC", label: "NIT/RUC", width: 100 },
      { dataKey: "Cliente", label: "Cliente", width: 250 },
      { dataKey: "Nombre", label: "Nombre", width: 250 },
      { dataKey: "Observacion", label: "Observacion", width: 350 },
    ]

    cortarArreglo(head,data.data);

    if (data.data.length > 0) {
       data.columns[0]["type"] = "text";
       data.columns[3]["width"] = 100;
       data.columns[4]["width"] = 250;
       data.columns[5]["width"] = 200;
       data.columns[6]["width"] = 120;
       data.columns[6]["type"] = "hidden";
       data.columns[10]["type"] = 100;
      // data.columns[8]["type"] = "hidden";

    }
    if (getState().bandejaaprobacion.filters.estado !== "T") {
       let x = data.data.filter(
         (e) => e.Estado === getState().bandejaaprobacion.filters.estado
       );
        dispatch({ type: RECEIVE_OLAS_LIST, columns: data.columns, payload: x });
    } else {
        dispatch({  type: RECEIVE_OLAS_LIST,   columns: head,  payload: data.data });
      //  dispatch({  type: RECEIVE_OLAS_LIST,   columns: data.columns,  payload: data.data  });
    }
  } catch (error) {
    let msgError = "";
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_OLAS_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0  ? "No existe Registro  para esta fecha "  : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0 ? ""  : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const btnSearchPasePedido = () => async (dispatch, getState) => {
  console.log('Pasa Pedido')
  if (getState().bandejaaprobacion.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
// //si quiere que sea antes debes averiguar si existe ya definidad para este elemento las validaciones y como activarla 
//  if (getState().bandejaaprobacion.filters.desde > getState().bandejaaprobacion.filters.hasta ) {
//     dispatch(
//       openMessageBox({
//         button: "ok",
//         icon: "warning",
//         message: " Verificar fecha ingresada",
//         callback: () => dispatch(closeMessageBox()),
//       })
//     );
//     return;
//   }

//   if ( Date(getState().bandejaaprobacion.filters.desde) > Date() || (getState().bandejaaprobacion.filters.hasta) > Date() ) {
//  dispatch(
//    openMessageBox({
//      button: "ok",
//      icon: "warning",
//      message: " Verificar fecha ingresada mayor que hoy",
//      callback: () => dispatch(closeMessageBox()),
//    })
//  );
//  return;
// }

  if (getState().bandejaaprobacion.loading) {
    return;
  }
  dispatch({ type: SET_MASK, loading: true });
///reportebandejaaprobacion reporteaprobacionpedidos
  try {
    let url =
      `${
       getState().main.resources.uriSvcSalesMobilityAPI}api/v1/reportes/reportebandejaaprobacion?` +
      `tipo=${getState().bandejaaprobacion.filters.ps}` +
      `&distribuidorId=${getState().bandejaaprobacion.filters.distribuidorId}` +
      `&desde=${format(getState().bandejaaprobacion.filters.desde, "yyyy-MM-dd")}` +
      `&hasta=${format(getState().bandejaaprobacion.filters.hasta, "yyyy-MM-dd")}` +
      `&vendedorid=${getState().bandejaaprobacion.filters.vendedorId}`+
      `&nombre=${getState().bandejaaprobacion.filters.nombre}`;

    let response = await axios.get(encodeURI(url));
    let data = response.data;

    //console.log(data, 'Para verificar estados pase ALY!!!!!') 
    if (data.length === 0){
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );

      dispatch({ type: SET_MASK, loading: false });

      return;
    }

    const head = [
      { dataKey: "Codigo", label: "Codigo de Pedido", width: 100},
      { dataKey: "Estado", label: "Estado", width: 100},
      { dataKey: "Fecha", label: "Fecha", width: 100 },
      { dataKey: "NIT/RUC", label: "NIT/RUC", width: 100 },
      { dataKey: "Cliente", label: "Cliente", width: 200 },
      { dataKey: "Nombre", label: "Nombre Vendedor", width: 250 },
      { dataKey: "Observacion", label: "Observación", width: 350},

    ]
    
    cortarArreglo(head, data.data);
    const columns = response.data.columns;
    
    if (data.data.length > 0) {
      data.columns[10]["type"] = 100;
     // data.columns[0]["type"] = "text";
      data.columns[3]["width"] = 100;
      data.columns[4]["width"] = 250;
      data.columns[5]["width"] = 200;
      data.columns[6]["width"] = 120;
      data.columns[6]["type"] = "hidden";
    //  data.columns[7]["width"] = "hidden";

    }
    if (getState().bandejaaprobacion.filters.estado !== "T") {
      let x = data.data.filter(
        (e) => e.Estado === getState().bandejaaprobacion.filters.estado
        );
        dispatch({ type: RECEIVE_PASE_PEDIDO_LIST, columns: head, payload: x , columnsexport:columns});
        console.log( 'Ahora paso por aqui para cambiar por color', x)
      } else {
        
    //  console.log(head, data.data , 'Pase antes de cortar Arreglo!!!!!') 
        dispatch({  type: RECEIVE_PASE_PEDIDO_LIST,   columns: head,  payload: data.data , columnsexport:columns});
      //  dispatch({  type: RECEIVE_OLAS_LIST,   columns: data.columns,  payload: data.data  });
    }
  } catch (error) {
    let msgError = "";
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_OLAS_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0  ? "No existe Registro  para esta fecha "  : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0 ? ""  : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const btnSearchAprobarCobranza = () => async (dispatch, getState) => {
  console.log('Aprobar Cobranza')
  if (getState().bandejaaprobacion.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  if (getState().bandejaaprobacion.loadingC) {
    return;
  }

  dispatch({ type: SET_MASK, loading: true });
  try {
    let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Cobranzas/cobranzasaprobacion?` +
      `distribuidorId=${getState().bandejaaprobacion.filters.distribuidorId}` +
      `&vendedorId=${getState().bandejaaprobacion.filters.vendedorId}`+
      `&nombre=${getState().bandejaaprobacion.filters.nombre}`+
      `&desde=${format( getState().bandejaaprobacion.filters.desde, "yyyy-MM-dd")}` +
      `&hasta=${format( getState().bandejaaprobacion.filters.hasta,"yyyy-MM-dd" )}` ;

    let response = await axios.get(encodeURI(url));
    let data = response.data;

    if (data.length === 0){
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    let columns = [
      { dataKey: 'fechaCobranza', label: 'Fecha ', width: 150 },
      { dataKey: 'responsable', label: 'Responsable', width: 200 },
      { dataKey: "tipoCobranza", label: "Tipo Cobranza", width: 100 },//para cuando lo crean e la tabla
      { dataKey: "nroDocumento", label: "Nro. Documento", width: 100 },//para cuando lo crean e la tabla
      { dataKey: 'banco', label: 'Banco', width: 200 },
      { dataKey: 'sucursal' ,  label: 'Almacén',   width: 110 },
      { dataKey: 'cliente', label: 'Cliente', width: 290 },
      { dataKey: 'nit', label: "NIT/RUC", width: 100 },
      { dataKey: 'moneda', label: 'Moneda', width: 100 },
      { dataKey: 'monto' ,  label: 'Monto',  width: 90 },
      { dataKey: 'estado', label: 'Estado', width: 70 },  
  ]

  let columnsexp = [
    { dataKey: 'CobranzaId', label: 'CobranzaId', width: 150 },
    { dataKey: 'FechaCobranza', label: 'Fecha ', width: 150},
    { dataKey: 'Responsable', label: 'Responsable', width: 200 },
    { dataKey: "tipoCobranza", label: "Tipo Cobranza", width: 100 },//para cuando lo crean e la tabla
      { dataKey: "nroDocumento", label: "Nro. Documento", width: 100 },
    { dataKey: 'Sucursal' ,  label: 'Almacén',   width: 110 },
    { dataKey: 'Banco', label: 'Banco', width: 200 },
    { dataKey: 'Cliente', label: 'Cliente', width: 290 },
    { dataKey: 'Nit', label: "NIT/RUC", width: 100 },
    { dataKey: 'Moneda', label: 'Moneda', width: 150 },
    { dataKey: 'Monto' ,  label: 'Monto',  width: 100 },
    { dataKey: 'Estado', label: 'Estado', width: 100 }, 
]

dispatch({type: RECEIVE_APROBAR_COBRANZA_LIST, columns:  columns, payload: data});

 //valida si es en dolares o soles la cobranza
      data.map((e)=>console.log( e.moneda === 1 ? e.moneda ='SOL': e.moneda ='DOLAR' ))
   

    if (getState().bandejaaprobacion.filters.estado !== "T") {
      let x = data.filter(
        (e) => e.estado === getState().bandejaaprobacion.filters.estado
        );

          dispatch({ type: RECEIVE_APROBAR_COBRANZA_LIST, columns: columns, payload: x , columnsexport:columnsexp});
      } else {
        dispatch({type: RECEIVE_APROBAR_COBRANZA_LIST,   columns: columns,  payload: data , columnsexport:columnsexp});
    }

  } catch (error) {
    let msgError = "";
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_OLAS_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0  ? "No existe Registro  para esta fecha "  : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0 ? ""  : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const btnSearchDescuento = () => async (dispatch, getState) => {
  console.log('Descuento')

  if (getState().bandejaaprobacion.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  if (getState().bandejaaprobacion.store.loading) {
    return;
  }

  dispatch({ type: SET_MASK, loading: true });

  try {
    let url =
      `${
      getState().main.resources.uriSvcSalesMobilityAPI}api/v1/reportes/reportebandejaaprobacion?` +
      `tipo=${getState().bandejaaprobacion.filters.ds}` +
      `&distribuidorId=${getState().bandejaaprobacion.filters.distribuidorId}` +
      `&desde=${format(getState().bandejaaprobacion.filters.desde, "yyyy-MM-dd")}` +
      `&hasta=${format(getState().bandejaaprobacion.filters.hasta, "yyyy-MM-dd")}` +
      `&vendedorid=${getState().bandejaaprobacion.filters.vendedorId}`+
      `&nombre=${getState().bandejaaprobacion.filters.nombre}`;


    let response = await axios.get(encodeURI(url));
    let data = response.data;
    let columns = response.data.columns

    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    const head = [
      { dataKey: "Codigo", label: "Codigo de Pedido", width: 100},
      { dataKey: "Fecha", label: "Fecha", width: 100 },
      { dataKey: "Estado", label: "Estado", width: 100 },
      { dataKey: "NIT/RUC", label: "NIT/RUC", width: 100 },
      { dataKey: "Cliente", label: "Cliente", width: 250 },
      { dataKey: "Nombre", label: "Nombre", width: 250 },
      { dataKey: "Observacion", label: "Observacion", width: 350 },
    ]

    cortarArreglo(head,data.data, 'DESCUENTO');

    if (data.data.length > 0) {
       data.columns[3]["width"] = 100;
       data.columns[4]["width"] = 250;
       data.columns[5]["width"] = 200;
       data.columns[6]["width"] = 120;
       data.columns[6]["type"] = "hidden";
       data.columns[10]["type"] = 100;
    }

    if (getState().bandejaaprobacion.filters.estado !== "T" ) {
       let x = data.data.filter(
         (e) => e.Estado === getState().bandejaaprobacion.filters.estado
       );
        dispatch({ type: RECEIVE_OLAS_LIST, columns: head, payload: x , columnsexport:columns});
    } else {
        dispatch({  type: RECEIVE_OLAS_LIST,   columns: head,  payload: data.data, columnsexport:columns });
    }
  } catch (error) {
    let msgError = "";
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_OLAS_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0  ? "No existe Registro  para esta fecha "  : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0 ? ""  : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

//HISTORICO
export const btnSearchHistorico = (row) => async (dispatch, getState) => {
  if (getState().bandejaaprobacion.store.loading) {
    return;
  }

  dispatch({ type: SET_MASK, loading: true });

  try {
    var url = `${ getState().main.resources.uriSvcSalesMobilityAPI }api/v1/Clientes/historicodocumento?` +
      `distribuidorId=${getState().bandejaaprobacion.filters.distribuidorId}` +
      `&clienteId=${getState().bandejaaprobacion.store.selectedPase !== 0 && getState().bandejaaprobacion.store.selectedPase[0].ClienteId}`+
      `&desde=${format( getState().bandejaaprobacion.filtersH.desdeHistorico, "yyyy-MM-dd")}` +
      `&hasta=${format( getState().bandejaaprobacion.filtersH.hastaHistorico,"yyyy-MM-dd" )}` ;

    var response = await axios.get(encodeURI(url));
    let data = response.data;

     dispatch({type:  RECEIVE_HISTORICO_DETAIL,  columns:  [], payload: data,  });
   
  } catch (error) {
    let msgError = "";
    console.log(error);
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_OLAS_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0
            ? "No existe Registro  para esta fecha "
            : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

//ESTADO DE CUENTA
export const btnSearchPorCobrar = (row) => async (dispatch, getState) => {
  if (getState().bandejaaprobacion.store.loading) {
    return;
  }

  dispatch({ type: SET_MASK, loading: true });

  try {
    var url = `${ getState().main.resources.uriSvcSalesMobilityAPI }/api/v1/Reportes/reporte/cuentacorrienteventa?` +
   // `clienteId=${18830}` ; 
    `clienteId=${row.ClienteId}` ;

    var response = await axios.get(encodeURI(url));
    let data = response.data;

    if (data.length === 0) {
      dispatch({ type: SET_MASK, loading: false });
      return;
    }

      dispatch({type:  RECEIVE_OLAS_PORCOBRAR,  columns: [],   payload: data });

   
  } catch (error) {
    let msgError = "";
 //   console.log(error);
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_OLAS_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0  ? "No existe Registro  para esta fecha "  : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0  ? "" : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message;
      }
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().bandejaaprobacion.table.columns.map((item, i) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
    return {
      // ...item,
      //  width: column.width
    };
  });
};

export const onUpdateColumnPase = (column) => (dispatch, getState) => {
  getState().bandejaaprobacion.tablePase.columns.map((item, i) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
    return {
      // ...item,
      //  width: column.width
    };
  });
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(
            onUpdateFieldFilter("distribuidorId", result[0].distribuidorId)
          );
          dispatch(
            onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`)
          );
          dispatch(onUpdateFieldFilter("supervisorId", ""));
          dispatch(onUpdateFieldFilter("dSupervisor", ""));
          dispatch(onUpdateFieldFilter("vendedorId", ""));
          dispatch(onUpdateFieldFilter("dVendedor", ""));
        }
      },
    })
  );
};

export const onSearchVendedorClick = () => async (dispatch, getState) => {
  let fields = [
    {
      dataKey: "nombre",
      label: "Nombre",
      type: "string",
      search: true,
      width: 225,
    },
    { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 },
  ];

  if (getState().bandejaaprobacion.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Vendedor",
      getData: async (params) => {
        let url =
          `${
            getState().main.resources.uriSvcSalesMobilityAPI
          }/api/v1/Vendedores/listarvendedoresxdistribuidor` +
          `?distribuidorId=${getState().bandejaaprobacion.filters.distribuidorId}`;
        let response = await axios.get(encodeURI(url), { params: params });
        
        return searchVendedor(response, params.nombre);
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("vendedorId", result[0].usuarioId)); //    usuarioId));
          dispatch(onUpdateFieldFilter("dVendedor", `${result[0].nombre}`));
        }
      },
    })
  );
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
  dispatch({ type: RECEIVE_APROBAR_COBRANZA_LIST, columns: [], payload: [] });
  dispatch({type: RECEIVE_PASE_PEDIDO_LIST,  columns:  [],   payload: [] });
  dispatch({type: RECEIVE_OLAS_LIST,  columns: [],   payload: [] });
};

export const onUpdateFieldFilterH = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTERH, key, value });
};

export const onUpdateInitial = (key, value) => (dispatch, getState) => {

  dispatch({type:  RECEIVE_HISTORICO_DETAIL,  columns:  [],   payload: [] });
  dispatch({type:  RECEIVE_OLAS_PORCOBRAR,  columns: [],   payload: [] });
};

export const restartState = () => ({ type: RESTART_STATE });

export const setOrder = (order, orderBy,key) => ({
  type: SET_ORDER,
  order,
  orderBy,
  key: key,
});

export const setSelectedRow = (selected,key) =>  (dispatch) => {
  dispatch({   type: SET_SELECTED_ROW,  selected , key: key})
};

export const onUpdateFieldEntity = (value) => (dispatch) => {
  dispatch({ type: UPDATE_FIELD_ENTITYS, value });
};

export const onSetBandeja = (newValue) => (dispatch) => {
  dispatch({ type: ON_SET_BANDEJA , payload: newValue })
};

export const detalleCobranza = (item) => async (dispatch, getState) => {

  if (getState().bandejaaprobacion.store.loading) {
    return;
  }
  
  dispatch({ type: SET_MASK, loading: true });
  
  try {
    let url =
      `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Reportes/cobranzas/listarcobranzas/detalle?cobranzaId=` +
      `${item["cobranzaId"]}`;

    let response = await axios.get(encodeURI(url));
    let datad = response;
    
    
    if(datad.data[0].Moneda === '1'){
      datad.data.map((e)=>console.log( e.Moneda ='Sol'))
    }else {
      datad.data.map((e)=>console.log( e.Moneda ='Dolar'))
    }
    
    dispatch({ type: RECEIVE_COBRANZA_DET, payload: datad.data });
  
  } catch (error) {
    let msgError = "";
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_OLAS_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0
            ? "No existe Registro  para esta fecha "
            : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      console.log(error);
      msgError = "Error al conectar con el servidor....";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};
export const HandleShows = (value) =>(dispatch)=>{

  dispatch({type: HANDLE_SHOW, payload: value})
 
    
}